import React, { useEffect } from 'react';
import {
   Banner as BannerStyle,
} from './style';
import { useRouter } from 'next/navigation';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Button, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { MobileMediaQuery } from 'src/utils/constants';

export type ComponentProps = any;

function WhiteContent({ isMobile, onRegister }: { isMobile?: boolean, onRegister: () => void }) {
   return (
      <Stack
         sx={{
            backgroundColor: 'white',
            width: '100%',
            maxWidth: 700,
            borderRadius: '0.5rem',
            padding: isMobile ? '1rem' : '3rem',
         }}>
         <Stack gap={3} mb={6}>
            <Typography variant="h1" fontWeight="bold">
               Facilite a gestão dos seus imóveis com a Alugue total
            </Typography>

            <Typography>
               Nós somos um híbrido de produto e serviço,
               onde damos as ferramentas para o acompanhamento e
               gestão de todos os atores envolvidos em uma operação
               de aluguel e administração de aluguéis.
               Além de oferecer o serviço da administração em si.
            </Typography>
         </Stack>

         <Button onClick={onRegister} variant='outlined' size='large' sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
            Cadastre-se gratuitamente
         </Button>
      </Stack>
   )
}

export default function Banner() {
   const isMobile = useMediaQuery(MobileMediaQuery);
   const router = useRouter();

   const handleRegisterClick = () => {
      router.push('/register');
   };

   return (
      <>
         {isMobile ? (
            <Stack sx={{ mt: "100px" }}>
               <Stack sx={{
                  backgroundImage: 'url(/images/new/header.png)',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '50vh',
               }} />
               <WhiteContent isMobile onRegister={handleRegisterClick} />
            </Stack>
         ) : (
            <BannerStyle sx={{
               backgroundImage: 'url(/images/new/header.png)',
               backgroundSize: 'cover',
               backgroundRepeat: 'no-repeat',
            }}>
               <Container>
                  <Stack sx={{ width: "100%", alignItems: "flex-end" }}>
                     <WhiteContent onRegister={handleRegisterClick} />
                  </Stack>
               </Container>
            </BannerStyle >
         )
         }
      </>
   );
};

