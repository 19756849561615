import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'

const data = [
  {
    id: 1,
    title: 'Repasse',
    description: "Ao efetuar o pagamento do aluguel, utilizamos uma ferramenta chamada 'Split de Pagamento', que distribui automaticamente os valores correspondentes a todos os envolvidos no recebimento. Isso garante um serviço de repasse em tempo real, sem burocracias.",
    img: {
      src: '/images/new/repasse.png',
      alt: 'Repasse SVG'
    }
  },
  {
    id: 2,
    title: 'Equipe contábil, e muito mais',
    description: 'Oferecemos suporte na obtenção de documentos necessários para declarações financeiras e auditorias. Contamos com uma equipe de assessoria contábil disponível para gerar os documentos exigidos, fornecendo também esclarecimentos especializados nessa área aos nossos clientes',
    img: {
      src: '/images/new/feito_para_voce.png',
      alt: 'Equipe contábil SVG'
    }
  },
  {
    id: 3,
    title: 'Serviço de cobrança e call center',
    description: 'Para realizar cobranças em atrasos - Além das cobranças automáticas via e-mail, sms, WhatsApp e ligações, temos também um setor responsável para tentar outras formas de contato caso haja algum atraso ou seja necessário o contato por algum outro motivo.',
    img: {
      src: '/images/new/contabilidade.png',
      alt: 'Call center SVG'
    }
  },
]

export default function Cards() {
  return (
    <Container sx={{ my: "8rem" }} id="sobre_nos">
      <Stack sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
        <Stack gap={4}>
          <Typography variant="h1" fontWeight="bold" textAlign="center">
            Conheça nosso suporte de Gestão
          </Typography>
        </Stack>

        <Box
          sx={{
            display: 'grid',
            marginTop: '4rem',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: '2rem',
          }}>
          {data.map(c => (
            <Stack key={c.id} sx={{ width: '100%', borderRadius: '0.5rem', boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}>
              <Stack sx={{ borderEndEndRadius: "0.5rem", height: 200, overflow: "hidden", justifyContent: "center", alignItems: "center" }}>
                <img src={c.img.src} alt={c.img.alt} width="100%"  />
              </Stack>
              <Stack mt={2} gap={2} padding={2}>
                <Typography variant="h3" fontWeight="bold">
                  {c.title}
                </Typography>
                <Typography variant="body2">
                  {c.description}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Box>
      </Stack>
    </Container>
  )
}
