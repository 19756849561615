
import React from 'react';


import Header from 'src/app_structure/home/components/Header';
import Banner from 'src/app_structure/home/components/Sections/Banner';
import Notification from 'src/app_structure/home/components/Sections/Notification';
import VideoDescription from 'src/app_structure/home/components/Sections/VideoDescription';
import Cards from 'src/app_structure/home/components/Sections/Cards';
import Footer from 'src/app_structure/home/components/Footer';

export type PageComponentProps = any;

const HomePage = ({}: PageComponentProps): React.ReactElement => {
   return (
      <>
         <Header />
         <Banner/>
         <Notification />
         <VideoDescription />
         <Cards />
         <Footer />
      </>
   );
};

export default HomePage;
