import { Button, Container, Link, Stack, Typography, useMediaQuery } from '@mui/material'
import { MobileMediaQuery } from 'src/utils/constants';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useRouter } from 'next/navigation';

export default function Notification() {
  const isMobile = useMediaQuery(MobileMediaQuery);
  const router = useRouter()

  return (
    <Container sx={{ my: "2rem" }}>
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{
          background: '#025CF420',
          gap: 4,
          padding: '1rem',
          borderRadius: '0.5rem',
          alignItems: 'center',
        }}>
        <img src="/images/new/box.png" height={100} width="auto" alt="Box" />
        <Stack gap={1} alignItems="flex-start">
          <Typography variant="h2" fontWeight="bold">
            Tenha suporte de call center, equipe jurídica, contábil e financeiro com o Alugue total.
          </Typography>

          <Button onClick={() => router.push("/#sobre_nos")} size="large" variant="text" endIcon={<KeyboardArrowRightOutlinedIcon />}>
            Saiba Mais
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}
