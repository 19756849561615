import { Container, Stack, Typography } from '@mui/material'
import React from 'react'

export default function VideoDescription() {
  return (
    <Container sx={{ my: "8rem" }}>
      <Stack sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
        <Stack gap={4}>
          <Typography variant="h1" fontWeight="bold" textAlign="center">
            Multiplas ferramentas em um só sistema
          </Typography>

          <Typography textAlign="center">
            A empresa possui um call center, equipe jurídica, contábil e financeira para realizar cobranças, solicitar documentos e garantir
            <br /> o cumprimento de cláusulas e acordos. Utilizando diversas formas de contato, buscam assegurar a operação independente
            <br /> e evitar incômodos ou transtornos para todas as partes envolvidas.
          </Typography>
        </Stack>

        <Stack sx={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '0.5rem',
          marginTop: '4rem'
        }}>
          <video
            src="/videos/video.mp4"
            width="100%"
            height="auto"
            autoPlay
            loop
            // controls
          />
        </Stack>
      </Stack>
    </Container>
  )
}
