import { Box, Button, Paper, Stack, Typography, styled } from '@mui/material';

export const Banner = styled(Stack)(({ theme, ...props }) => ({
   height: 'calc(100vh - 100px)',
   zIndex: 0,
   marginTop: '100px',
   overflow: 'hidden',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'center',

   // '@media (max-width: 768px)': {
   //    height: '850px',
   //    paddingBottom: '0px',
   //    paddingTop: '0px',
   // },
}));
