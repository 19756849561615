"use client"

import { Suspense } from "react"
import HomePage from "src/app_structure/home"


export default function HomeAppPage() {
  return (
    <Suspense>
      <HomePage />
    </Suspense>
  )
}
